#navbar {
  background-color: rgb(242, 0, 0);
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
  transition: top 0.3s;
}

#navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 15px;
  text-decoration: none;
  font-size: 17px;
}

#navbar a:hover {
  background-color: #ddd;
  color: black;
}
.ContextText {
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap;
  /* Newer versions of Chrome/Safari*/
  word-break: break-all;
  white-space: normal;
}

.ResultCheckBg {
  position: relative;
}
.ResultCheckBg > img,
.overlay-text {
  position: absolute;
}
.containerResult {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: 600;
  margin-left: 9px;
  cursor: pointer;
}
.Center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.FullHeight {
  height: 60vh;
  min-height: 60vh;
}

.Image {
  width: 100%;
  height: 100%;
}

.blink_me {
  animation: blinker 1s linear infinite;
  color: "#2196f3";
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.ScrollMenu {
  margin: 16px;
}
.Sidespace {
  width: 100%;
  height: 100px;
  background: white;
  margin-left: 16px;
}

div.scroll {
  overflow: auto;
  display: flex;
  margin-right: 5%;
  margin-left: 5%;
}
.wrapperClass {
  margin-right: 5%;
  margin-left: 5%;
  overflow: scroll;
}

.scrollContainer {
  justify-content: center;
}
@media (max-width: 800px) {
  .scrollContainer {
    justify-content: flex-start;
  }
}

.CotestContainer {
  width: auto;
  min-width: 300px;
  height: 250px;
  max-width: 500px;
}

@media (max-width: 800px) {
  .CotestContainer {
    width: max-content;
    min-width: 300px;
    height: 250px;
    max-width: 500px;
  }
}

.SectionContainer {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10;
  padding: 16;
}

@media (max-width: 800px) {
  .SectionContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10;
    padding: 16;
  }
}

.Inputstyle {
  height: auto;
  width: 50%;
  align-self: center;
}

@media (max-width: 800px) {
  .Inputstyle {
    height: auto;
    width: 100%;
    align-self: center;
  }
}

.LoginContainer {
  /* display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center; */
  display: flex;
  flex-direction: row;
  text-align: center;
}

@media (max-width: 800px) {
  .LoginContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.LoginContainer1 {
  width: 40%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
}

@media (max-width: 800px) {
  .LoginContainer1 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }
}

.LoginContainer1Search {
  width: 30%;
  text-align: center;
  /* margin-left: auto;
  margin-right: auto;
  align-self: center; */
}

@media (max-width: 800px) {
  .LoginContainer1Search {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }
}

.LoginContainerStart {
  width: 50%;
  text-align: start;
  margin-left: auto;
  margin-right: auto;
  align-self: flex-start;
}

@media (max-width: 800px) {
  .LoginContainerStart {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }
}

.FooterText {
  color: black;
}

.FooterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
}

@media (max-width: 800px) {
  .FooterContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
  }
}

.FooterSection {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  width: 100%;
  justify-content: center;
}

@media (max-width: 800px) {
  .FooterSection {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    width: 100%;
    justify-content: center;
  }
}

.FooterSectionSocial {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
}

@media (max-width: 800px) {
  .FooterSectionSocial {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
  }
}

.ProfileSection {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 16px;
}

.ProfileContaner {
  display: flex;
  flex: 1;
  flex-direction: row;
  text-align: start;
  margin-top: 8px;
  background-color: white;
}

@media (max-width: 800px) {
  .ProfileContaner {
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-top: 8px;
    background-color: transparent;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textwrap {
  word-wrap: break-word;
}

.DashBoardContaner {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 8px;
}

@media (max-width: 800px) {
  .DashBoardContaner {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
  }
}

.DashBoardSection {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  align-items: center;
  color: blue;
}

@media (max-width: 800px) {
  .DashBoardSection {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 8px;
    align-items: center;
    color: blue;
  }
}

.DashBoardText {
  margin-left: 0px;
}

@media (max-width: 800px) {
  .DashBoardText {
    margin-left: 16px;
  }
}
